import { useEffect, useState } from 'react';

let counterID: NodeJS.Timeout;
const useCounter = (initialValue: number, freezeCount = false) => {
    const [count, setCount] = useState(initialValue);
    const [startCount, setStartCount] = useState(false);

    useEffect(() => {
        if (!startCount) {
            clearInterval(counterID);
            setCount(initialValue);
            return;
        }
        counterID = setInterval(() => {
            setCount((count) => {
                if (count === 1) {
                    if (freezeCount) {
                        clearInterval(counterID);
                    } else {
                        return initialValue;
                    }
                }
                return count - 1;
            });
        }, 1000);
        return () => clearInterval(counterID);
    }, [startCount]);

    const reset = () => {
        setCount(initialValue);
    };

    const beginCount = () => {
        setStartCount(true);
    };

    const stopCount = () => {
        setStartCount(false);
    };

    return { count, reset, beginCount, stopCount };
};

export default useCounter;
