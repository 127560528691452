export const regexThatAcceptsAlphabetsAndSpaces = /[^A-Za-z\s]/g; // this will accept only alphabets

export const addressPageRegex = /^\s+|[^\w\d\s\-/,/,\b]+/g; // this will accept alphabets, numbers, spaces and few special characters like _,-,/,

export const emailRegex = /^[a-zA-Z0-9.]{6,}@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

export const upiIdRegex = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/;

const regexMap = {
    digits: /^\d+$/,
    alphabetsAndDigitsWithSpace: /^(?:[a-zA-Z0-9]+  ?)*[a-zA-Z0-9]+ ?$/,
    addressLines: /^(?:.*\S.*?){3,}$/, // at least 3 characters without counting the spaces
    alphabetsWithSpace: /^(?=.*[a-zA-Z])[a-zA-Z](?: ?[a-zA-Z])* ?$/,
    pan: /^([a-zA-Z]){3}([P]){1}([a-zA-Z]){1}([0-9]){4}([a-zA-Z]){1}$/,
    companyName: /^(?! )(?!.* {2})[\w&. ]*$/,
    digitsWithSlash: /^[0-9/]*$/,
    alphabetsAndDigits: /^[a-zA-Z0-9]*$/,
    digitsWithSpace: /^[\d\s]+$/,
    space: /\s/g,
    groupFourCharacter: /.{1,4}/g,
    http_5xx: /^5.*/,
    charactersWithSingleSpaceInBetween: /^\S(?:.*?\S)?\s?$/,
};

export default regexMap;
