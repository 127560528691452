import { PollingStatus } from '../../../types/common/response.type';
import Loader from '../../Loader';
import Button from '../../base/Button';

interface PollingProps {
    header: string;
    showCancel?: boolean;
    onCancel?: () => void;
    status?: PollingStatus;
    onRetry?: () => void;
    retryButtonText?: string;
    subTitle?: string;
    failedMessage?: string;
}

const Polling = ({
    header,
    showCancel = false,
    onCancel,
    status = PollingStatus.PENDING,
    onRetry,
    retryButtonText,
    subTitle,
    failedMessage,
}: PollingProps) => {
    if (status === PollingStatus.FAILURE) {
        return (
            <div className="hight-dvh z-[999] fixed top-0 left-0 bg-[rgba(0,0,0,0.6)] w-full backdrop-blur-[20px]">
                <div className="bg-white absolute opacity-100 rounded-t-[16px] w-full left-1/2 -translate-x-1/2 px-[16px] max-w-[450px] bottom-0 pt-[24px] pb-[16px]">
                    <div className="flex justify-center mb-[16px]">
                        <img src="/images/poll_close.svg" alt="X" className="w-[130px] h-[80px]" onClick={onRetry} />
                    </div>
                    <p className="text-[18px] font-robotoBold text-contentColor text-center">{failedMessage}</p>
                    {subTitle && (
                        <p className="text-[12px] text-lightBody px-[10px] mt-[16px] text-center">{subTitle}</p>
                    )}
                    <Button onClick={onRetry} className="w-full mt-[16px]">
                        {retryButtonText}
                    </Button>
                </div>
            </div>
        );
    }
    return (
        <div className="hight-dvh z-[999] fixed top-0 left-0 bg-[rgba(0,0,0,0.6)] w-full backdrop-blur-[20px]">
            <div className="bg-white absolute opacity-100 rounded-t-[16px] w-full left-1/2 -translate-x-1/2 px-[16px] max-w-[450px] bottom-0 pt-[24px] pb-[16px]">
                <p className="text-[18px] font-robotoBold text-contentColor text-center">{header}</p>
                <p className="text-[14px] text-lightBody text-center mt-1">Do not close the window or go back</p>
                <Loader centered={false} />
                {showCancel && (
                    <div onClick={onCancel} className="cursor-pointer text-center text-hyperLink text-[14px]">
                        Cancel and Retry
                    </div>
                )}
            </div>
        </div>
    );
};

export default Polling;
