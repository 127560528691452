import { ZeroPercentEMITag } from '../common/zero-percent-emi-tag';
import { useContext, useState } from 'react';
import { AppContext } from '../../context/app';
import { getDateInDayMonthYearFormat, getRoundedValue, getTenureStringFromPlans } from '../../utils';
import useQuery from '../../hooks/useQuery.hook';
import { PlanString } from './plan-string';
import PlanModal from './plan-modal';
import { Plan } from '../../types/plan.type';
import Shimmer from '../Shimmer'

export default function PlanCard() {
    const [showModal, setShowModal] = useState(false);
    const { queryParams } = useQuery();
    const { planId } = queryParams;
    const { state, updatePlanConfig } = useContext(AppContext);
    const { planConfig, cart } = state;
    const { plans, selectedPlan, loading } = planConfig ?? {};
    const { dp, emi, totalYouPay, tenure, startAt, endAt, isZeroPercent } = selectedPlan ?? {};
    const startDate = getDateInDayMonthYearFormat(startAt, 'upper', true);
    const endDate = getDateInDayMonthYearFormat(endAt, 'upper', true);
    const isShowPlansCard = plans.length > 1 && !planId;
    const tenureString = `${getTenureStringFromPlans(plans)} EMI Plans`;

    const ignorePlanCard = ['/failure', '/order/status', '/error'].includes(window.location.pathname);

    const handlePlanStringClick = () => {
        setShowModal(true);
    };

    if (ignorePlanCard) {
        return null;
    }

    if (loading) {
        return (
            <div className="w-full h-[130px]">
                <Shimmer />
            </div>
        );
    }
    return (
        <>
            <div className="py-0 px-[12px] rounded-[8px] border border-solid border-lightGrayOutline bg-white pb-[11px]">
                <div className="flex items-center gap-16 bg-lightGrayOutline px-16 w-fit mx-auto pt-[5px] pb-[6px] rounded-b-lg font-robotoMedium">
                    <p className="text-8 text-contentColor truncate max-w-[100px]">{cart?.merchantName}</p>
                    <div className="h-10 w-[1px] bg-[#D0D0D0]" />
                    <p className="text-8 text-contentColor truncate">Order Total: ₹{Math.round(cart?.orderValue)}</p>
                </div>
                <div className="flex justify-between item-center mt-[8px] mb-[6px]">
                    <div className="text-center font-robotoBold">
                        <p className="text-13 text-lightBody mb-[4px]">Pay Now</p>
                        <p className="text-12 text-titleDark mb-[2px]">₹{getRoundedValue(dp)}</p>
                        <p className=" font-robotoMedium text-10 text-lightBody">Downpayment</p>
                    </div>
                    <img src="/images/plus-icon.svg" />
                    <div className="text-center font-robotoBold">
                        <p className="text-13 text-lightBody mb-[4px]">EMI</p>
                        <p className="text-12 text-titleDark mb-[2px]">₹{getRoundedValue(emi)}</p>
                        <p className=" font-robotoMedium text-10 text-lightBody mb-[1px]">x{tenure} mons</p>
                        {isShowPlansCard && <PlanString planString={tenureString} onClick={handlePlanStringClick} />}
                    </div>
                    <img src="/images/arrow-right-icon.svg" />
                    <div className="text-center font-robotoBold">
                        <p className="text-13 text-lightBody mb-[4px]">Total</p>
                        <p className="text-12 text-titleDark mb-[2px]">₹{getRoundedValue(totalYouPay)}</p>
                        {isZeroPercent && (
                            <div className="mt-10">
                                <ZeroPercentEMITag />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex items-center gap-4 w-fit m-auto">
                    <img
                        src="/images/snapmint_green_small_logo.svg"
                        alt="snapmint-logo"
                        className="w-[18px] h-[17px]"
                    />
                    <p className=" font-robotoMedium text-10 text-disableGary leading-3">Pay EMIs on Snapmint App</p>
                    <span className="bg-disableGary w-4 h-4 rounded-lg"></span>
                    <div className="font-robotoMedium text-disableGary text-10 leading-3 flex items-center gap-1 relative bottom-[1px]">
                        <p dangerouslySetInnerHTML={{ __html: startDate }} />
                        <p>-</p>
                        <p dangerouslySetInnerHTML={{ __html: endDate }} />
                    </div>
                </div>
            </div>
            {showModal && <PlanModal onClose={() => setShowModal(false)} />}
        </>
    );
}
