import { useLocation } from 'react-router-dom';
import Button from '../components/base/Button';
import { useState } from 'react';
import useOrderSvc from '../services/order.svc';
import { convertObjectToQueryParams, handleFormPost } from '../utils';
import useQuery from '../hooks/useQuery.hook';
import { useContext, useEffect } from 'react'
import { EventsContext } from '../context/events'
import Loader from '../components/Loader';
import useCounter from '../hooks/couter'

export default function Failure() {
    const [loading, setLoading] = useState(false);
    const { count, beginCount } = useCounter(Number(process.env.REACT_APP_FAILURE_REDIRECT_COUNT || 10), true);
    const {
        queryParams: { id },
    } = useQuery();
    const { getFailureUrl } = useOrderSvc();

    const { state: routeState } = useLocation();
    const { captureEvent } = useContext(EventsContext);
    const { message } = routeState || {};

    useEffect(() => {
        captureEvent('failure_pv');
        if (id) {
            beginCount();
        }
    }, []);

    useEffect(() => {
        if (count === 0) {
            handleContinueShopping();
        }
    }, [count]);

    const handleContinueShopping = async () => {
        try {
            setLoading(true);
            const { action, merchantParams, method } = await getFailureUrl();
            if (!action) {
                return window.close();
            }
            if (method?.toLowerCase() === 'post') {
                handleFormPost(action, merchantParams);
            } else {
                const params = convertObjectToQueryParams(merchantParams);
                window.location.assign(`${action}?${params}`);
            }
        } catch (error) {
            window.close();
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="flex items-center flex-col">
            <img className="m-auto" src="/images/dog.svg" />
            <p className="mt-[52px] text-titleDark text-16 text-center">{message || 'Something went wrong'}</p>
            {id && (
                <>
                    <Button className="!w-[250px] mt-[16px]" onClick={handleContinueShopping}>
                        Pay with UPI/Others
                    </Button>
                    <p className='text-14 text-lightBody mt-[52px]'>Redirecting you in {count} sec...</p>
                </>
            )}
        </div>
    );
}
