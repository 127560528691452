import useRestClient from '../hooks/rest.hook';
import useQuery from '../hooks/useQuery.hook';
import { PollingRes } from '../types/common/response.type';
import { DpDataRes, DpReq, DpRes, ICardBinInfo, NetBankingList, UpiIntentQrCode } from '../types/dp.type';
import { convertObjectToQueryParams } from '../utils';

export default function useDpSvc() {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const { id, fetchType } = queryParams;

    async function getUpiData(): Promise<DpDataRes> {
        return restClient.get(`/payments/upi-intents?id=${id}`);
    }

    async function getNetBankingBankList(): Promise<NetBankingList> {
        return restClient.get(`/payments/netbanking/banks?id=${id}`);
    }

    async function getUpiQrCode(): Promise<UpiIntentQrCode> {
        return restClient.get(`/payments/upi-qrcode?id=${id}`);
    }

    async function submitDp(dpReq: DpReq, method: string = ''): Promise<DpRes> {
        const params = convertObjectToQueryParams({
            ...queryParams,
            fetchType,
            poll: true,
            ...(method ? { method } : {}),
        });
        const url = `${window.location.origin}/dp?${params}`;
        const requestData = {
            ...dpReq,
            successUrl: url,
            failureUrl: url,
        };
        return restClient.post(`/payments?id=${id}`, requestData);
    }

    async function updateMitcStatus(status: boolean): Promise<void> {
        return restClient.post(`/payments/mitc?id=${id}`, { status });
    }

    async function getStatus(): Promise<PollingRes> {
        return restClient.get(`/payments/status?id=${id}`);
    }

    async function getMitcUrl(): Promise<{ mitcUrl: string }> {
        return restClient.get(`/payments/mitc-url?id=${id}`);
    }

    async function getDebitCardInformation(digits: string): Promise<ICardBinInfo> {
        return restClient.get(`/payments/debit-card/bin?id=${id}&digits=${digits}`);
    }
    return {
        getUpiData,
        getNetBankingBankList,
        updateMitcStatus,
        getStatus,
        submitDp,
        getUpiQrCode,
        getMitcUrl,
        getDebitCardInformation,
    };
}
