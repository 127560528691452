export enum ErrorTypeEnum {
    REJECTION = 'REJECTION',
    VALIDATION = 'VALIDATION',
    REQUIRED = 'REQUIRED',
}

export enum StatusEnum {
    PROCESSING = 'PROCESSING',
    REJECTED = 'REJECTED',
    FAILED = 'FAILED',
    ORDER_CONFIRMED = 'ORDER_CONFIRMED',
    COMPLETED = 'COMPLETED',
}

export enum OrderStatus {
    PROCESSING = 'PROCESSING',
    REJECTED = 'REJECTED',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    COMPLETED = 'COMPLETED',
}

export enum CheckoutState {
    PROFILE = 'PROFILE',
    ADDRESS = 'ADDRESS',
    DEFAULT = 'DEFAULT',
    PLAN = 'PLAN',
}

export interface Checkout {
    id: string;
    orderStatus: OrderStatus;
    required: CheckoutState;
    requiredFields: string[];
    message: string;
    errorType: ErrorTypeEnum;
}

export enum EligibilityStatus {
    APPROVED = 'approved',
    REJECTED = 'rejected',
    KYC_REQUIRED = 'kyc_required',
    PAN_REQUIRED = 'pan_required',
}

export enum EligibilityState {
    PROFILE = 'PROFILE',
    KYC = 'KYC',
}

export interface Eligible {
    id: string;
    status: EligibilityStatus;
    required: EligibilityState;
    requiredFields: string[];
    message: string;
    emiLimit?: number;
    paylaterLimit?: number;
}

export enum PollingStatus {
    SUCCESS = 'success',
    PENDING = 'pending',
    FAILURE = 'failure',
    NOT_INIT = 'not_init',
}

export interface PollingRes {
    status: PollingStatus;
    message?: string;
}
