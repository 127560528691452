import { useContext } from 'react';
import { AppContext } from '../../context/app';
import { ZeroPercentEMITag } from '../common/zero-percent-emi-tag';
import {  Radio, Space } from 'antd';
import { getDateInDayMonthYearFormat, getEMIPercentage, getRoundedValue } from '../../utils';
import { Plan } from '../../types/plan.type';
import Overlay from '../common/overlay';
import Button from '../base/Button'

const PlanModal = ({ onClose }: { onClose: () => void }) => {
    const {
        state: {
            cart: { orderValue },
            planConfig: {
                plans,
                selectedPlan
            },
        },
        updatePlanConfig,

    } = useContext(AppContext);
    const downPayment = Math.round(selectedPlan?.dp);
    const startDate = selectedPlan?.startAt;

    return (
        <Overlay onClose={onClose} heading="EMI Plans">
            <>
                <div className="flex items-center gap-4 mb-8 font-robotoBold">
                    <img src="/icons/down-payment.svg" alt="down-payment" className="h-[24px] w-[24px]" />
                    <p className="text-primaryGray text-14 leading-5">
                        Pay only <span className="text-primaryGray leading-4">₹{downPayment} now</span>
                    </p>
                </div>
                <div className="font-robotoBold text-14 leading-5 text-contentColor my-8">Choose EMI tenure</div>
                <div className="text-lightBody italic font-robotoRegular text-12 my-8">
                    EMIs Starting <span dangerouslySetInnerHTML={{__html: getDateInDayMonthYearFormat(startDate, 'upper')}} /> 
                </div>
                <Radio.Group
                    className="w-full"
                    value={selectedPlan.planId}
                    onChange={(e) => {
                        updatePlanConfig({
                            selectedPlan: plans.find(({ planId }) => planId === e.target.value),
                        });
                    }}
                >
                    <Space direction="vertical" className="w-full gap-[10px]">
                        {plans.map(({ dp, emi, tenure, planId, isZeroPercent }, i) => {
                            const roi = getEMIPercentage({
                                dp,
                                emi,
                                orderValue,
                                tenure,
                            });
                            return (
                                <>
                                    <div className="pl-4 w-full flex justify-between">
                                        <Radio value={planId}>
                                            <p className="text-14 font-robotoBold text-titleDark">
                                                ₹{getRoundedValue(emi)}x{' '}
                                                <span className="text-lightBody !font-robotoMedium">
                                                    {tenure} months
                                                </span>
                                            </p>
                                        </Radio>
                                        <div>
                                            {isZeroPercent ? (
                                                <ZeroPercentEMITag />
                                            ) : (
                                                <span className="text-10 text-lightBody font-robotoMedium">
                                                    ({roi}% per mon*)
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {i < plans.length - 1 && <div className="h-[1px] w-full bg-grayOutline" />}
                                </>
                            );
                        })}
                    </Space>
                </Radio.Group>
                <div className="rounded-t-[16px] bg-white p-[16px] mt-[16px] w-full z-99">
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Pay in {selectedPlan?.tenure} Monthly EMIs
                    </Button>
                </div>
                <p className='italic text-12 text-lightBody'>*Interest charged on monthly basis</p>
            </>
        </Overlay>
    );
};

export default PlanModal;
