import { smsTypEnum } from '../context/otp';
import useRestClient from '../hooks/rest.hook';
import Auth from '../types/auth.type';

export default function useAuthSvc() {
    const restClient = useRestClient();

    async function sendOtp(mobile: string, type?: smsTypEnum): Promise<null> {
        return restClient.post('/pub/auth/otp', { mobile, smsType: type });
    }

    async function login(auth: Auth): Promise<{ tokenId: string }> {
        return restClient.post('/pub/auth/login', auth);
    }

    return {
        sendOtp,
        login,
    };
}
