import useRestClient from '../hooks/rest.hook';
import useQuery from '../hooks/useQuery.hook';
import Cart from '../types/cart.type';
import { Checkout } from '../types/common/response.type';
import { convertObjectToQueryParams } from '../utils';

export default function useCheckoutSvc() {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const { cartId, cartToken, merchantId, id } = queryParams;

    async function getCart(): Promise<Cart> {
        const params = convertObjectToQueryParams({ id: cartId, token: cartToken });
        return restClient.get(`/pub/carts?${params}`);
    }

    async function checkout(): Promise<Checkout> {
        const params = convertObjectToQueryParams({ cartId, cartToken, mid: merchantId });
        return restClient.post(`/checkout?${params}`);
    }

    async function getCheckoutState(): Promise<Checkout> {
        return restClient.get(`/checkout?id=${id}`);
    }

    return {
        getCart,
        checkout,
        getCheckoutState,
    };
}
