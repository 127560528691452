import useRestClient from '../hooks/rest.hook';
import Address from '../types/address.type';
import { Checkout } from '../types/common/response.type';
import useQuery from '../hooks/useQuery.hook';

export default function useAddressSvc() {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const { id } = queryParams;

    async function getAddresses(): Promise<Array<Address>> {
        return restClient.get(`/addresses?id=${id}`);
    }
    async function addAddress(address: Address): Promise<Checkout> {
        return restClient.post(`/addresses?id=${id}`, address);
    }
    async function getCity(pin: string): Promise<{ city: string; state: string }> {
        return restClient.get(`/pub/addresses/city?pincode=${pin}&id=${id}`);
    }

    return {
        getAddresses,
        addAddress,
        getCity,
    };
}
