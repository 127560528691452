import { getClassName } from '../../utils';

interface IFooterProps {
    type?: string;
}

export default function Footer({ type = 'large' }: IFooterProps) {
    const FooterData = [
        {
            imgLogo: `/snapmint-green-logo.svg`,
            text: '1 Cr+ Users',
            imgStar: `/icons/google-pay-stars.svg`,
        },
        {
            imgLogo: `/g-pay.svg`,
            text: '<span>Available on <br /> Google Pay</span>',
        },
        {
            imgLogo: `/rbi.svg`,
            text: '<span>RBI License No. <br /> N-13.02352</span>',
        },
    ];

    const footerBottomTextClasses = getClassName(
        {
            'text-10': type === 'large',
            'text-6': type !== 'large',
        },
        'pt-[2px] text-center  font-robotoRegular text-contentColor'
    );

    const footerTitleClasses = getClassName(
        {
            'text-10 text-center': type === 'large',
            'text-7': type !== 'large',
        },
        'font-openSansBold text-titleDark pt-3'
    );

    return (
        <div className="w-full max-w-[450px]">
            <div className={footerTitleClasses}>100% secure transactions</div>
            <div className="bg-pie h-[1px] my-[10px] w-[calc(100%-32px)] m-auto" />
            <div className="px-[2px] pb-[10px] flex justify-between items-center">
                {FooterData.map((data, index) => {
                    const { imgLogo, text, imgStar } = data;
                    return (
                        <div key={index} className="flex justify-between flex-col flex-1 items-center">
                            <img
                                src={`/images/${imgLogo}`}
                                className={type === 'large' ? 'h-[30px]' : 'h-[24px]'}
                                alt={imgLogo}
                            />
                            <div className={footerBottomTextClasses} dangerouslySetInnerHTML={{ __html: text }} />
                            {imgStar && <img src={imgStar} className="h-[10px] pt-1" alt={imgStar} />}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
