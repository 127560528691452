import useRestClient from '../hooks/rest.hook';
import useQuery from '../hooks/useQuery.hook';
import { Eligible } from '../types/common/response.type';
import { Profile } from '../types/profile.type';
import { convertObjectToQueryParams } from '../utils';

export default function useEligibilitySvc() {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const { merchantId } = queryParams;

    async function checkEligibility(profile?: Profile): Promise<Eligible> {
        const params = convertObjectToQueryParams({ mid: merchantId });
        return restClient.post(`/eligible?${params}`, {
            pan: profile?.pan,
        });
    }

    return {
        checkEligibility,
    };
}
