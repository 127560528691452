import Axios from 'axios'
import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: '' };
    }

    promiseRejectionHandler = (error) => {
        this.setState({
            hasError: true
        });
        if (error?.reason?.config) {
            const { url = '', message = '' } = error.reason.config
            Axios.post(`${process.env.REACT_APP_APIS_BASE_URL}/pub/logs`, {
                level: 'error',
                data: JSON.stringify({ url, message }),
                origin: 'ck-3.0',
            }).catch(() => {});
        }
    }

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1 className='flex text-center justify-center items-center'>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}
