const Overlay = ({ children, onClose, heading }: { children: JSX.Element; onClose: () => void; heading: string }) => {
    return (
        <div className="bg-[rgba(0,0,0,0.6)] fixed h-[100vh] z-[1000] w-full max-w-[450px] top-0 right-0 bottom-0 left-1/2 px-[15px] -translate-x-1/2">
            <div className="rounded-[16px] bg-white shadow-card absolute bottom-1/2 translate-y-1/2 w-[calc(100%-30px)]">
                <div className="flex justify-between py-[20px] px-16">
                    <p className="font-robotoBold text-14 text-primaryGray">{heading}</p>
                    <img className="cursor-pointer" src="/icons/close.svg" alt="close" onClick={onClose} />
                </div>
                <div className="border-[1px] !border-solid border-grayOutline"></div>
                <div className="p-16">{children}</div>
            </div>
        </div>
    );
};

export default Overlay;
