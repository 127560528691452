import { Button as AntButton, ButtonProps } from 'antd';

const Button = (props: ButtonProps) => {
    const { size = 'large', type = 'primary' } = props;
    return (
        <AntButton
            {...props}
            size={size}
            type={type}
            disabled={false}
            className={`${!props.disabled ? 'hover:text-primaryGreen' : 'bg-[#90A4A7] text-[#FFFFFF] hover:text-white'} shadow-none w-full ${props.className}`}
        >
            {props.children}
        </AntButton>
    );
};

export default Button;
