export enum DpMethod {
    UPI = 'UPI',
    NET_BANKING = 'NET_BANKING',
    DEBIT_CARD = 'DEBIT_CARD',
    UPI_INTENT = 'UPI_INTENT',
}

export enum DpUpiOption {
    UPI_ID = 'upi_number',
    UPI_INTENT = 'upi_intent',
}

export interface NetBankingBank {
    bankLogoUrl: string;
    channelCode: string;
    channelDisplayName: string;
    channelName: string;
    iconUrl: string;
}

export interface NetBankingList {
    netbankingBanks: NetBankingBank[];
    netbankingOpts: NetBankingBank[];
}

export interface UpiIntentQrCode {
    upiQrCodeUrl: string;
}

export interface SeamlessUpiIntent {
    iconUrl: string;
    name: string;
    packageName: string;
    paymentMode: string;
}

export interface DpDataRes {
    validUpiDomains: string[];
    upiIntents: SeamlessUpiIntent[];
}

export interface UpiListConfig {
    intentList: SeamlessUpiIntent[];
    domainList: string[];
}

export interface DpUpi {
    upiId: string;
}

export interface IDebitCard {
    cardName: string;
    cvv: number;
    digits: number;
    validity: string;
}

export interface INetBanking {
    channelCode: string;
}

export interface DpReq {
    paymentMethod: DpMethod;
    cardName?: string;
    cvv?: number;
    digits?: number | string;
    validity?: string;
    vpa?: string;
    seamlessUpiIntent?: string;
    bankCode?: string;
}

export interface DpRes {
    redirectUrl: string;
    params?: Record<string, string>;
}

export interface NetBankingConfig {
    bankList: NetBankingBank[];
    topBankList: NetBankingBank[];
    loading: boolean;
}

export interface UpiSelectConfig {
    selectedUpiIntent: string | null;
    selectedUpiOption: DpUpiOption | null;
}

export interface UpiQrCodeConfig {
    upiQrCodeUrl: string | null;
    upiQrCodeLoading: boolean;
}
export interface IDebitCardBinInfo {
    cardNo: string;
    cardInfo: ICardBinInfo | null;
    error: string | null;
}
export interface ICardBinInfo {
    cvvL: string;
    cnMin: string;
    cnMax: string;
}