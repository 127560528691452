import { Route, Routes } from 'react-router-dom';
import RouteType from '../types/route.type';
import { useContext } from 'react';
import { publicRoutes, protectedRoutes, commonRoutes } from './all-routes';
import { AppContext } from '../context/app'

export default function Index() {
    const {
        state: { user },
    } = useContext(AppContext);
    const userToken = localStorage.getItem('tk');
    const routes = [...commonRoutes, ...(userToken ? protectedRoutes : publicRoutes)];

    return (
        <Routes>
            {routes.map((route: RouteType, idx) => (
                <Route path={route.path} element={route.component} key={idx} />
            ))}
        </Routes>
    );
}
